:root{
	--nav-headbg: #FCFCFC;
	--sidebar-bg: #FCFCFC;
	--headerbg: #FCFCFC;
}

//background for nav header
@each $name, $color in $theme_backgrounds {
	[data-nav-headerbg="#{$name}"][data-theme-version="dark"],
    [data-nav-headerbg="#{$name}"] {
        @if $name != "color_1"  {
			--nav-headbg: #{$color};
			.brand-logo{
				.logo-abbr{
					.rect-primary-rect{
						fill:$white;
					}
					circle{
						fill:$color;	
					}
					.rect-primary-rect-1{
						fill:$white;
						stroke:$color;
					}
					path{
						stroke:$white;	
						fill: $white;
					}
				}
				.brand-title{
					path{
						fill:$white;	
					}	
				}
			}
			.hamburger{
				.line{
					background:black;	
				}	
			}
			&[data-sidebar-style="full"][data-layout="vertical"]{
				.menu-toggle .nav-header .nav-control .hamburger .line{
					background-color:$color;	
				}
			}
			&[data-sidebar-style="overlay"]{
				.nav-header .hamburger.is-active .line{
					background-color: $white;	
				}	
			}
			
			//special work for color
			&[data-nav-headerbg="color_8"]{
				.brand-title{
					path{
						fill:$black;
					}	
				}
				.hamburger{
					.line{
						background:$black;	
					}	
				}
			}
		}
		
	}
}
//background for sibebarbg
@each $name, $color in $theme_backgrounds {
	[data-sibebarbg="#{$name}"][data-theme-version="dark"],
    [data-sibebarbg="#{$name}"] {
        @if $name != "color_1"  {
			--sidebar-bg: #{$color};
			.plus-box{
				background: lighten($color: $color, $amount: 10%)	
			}
			.menu-toggle .deznav .metismenu li > ul{
				background:$color!important;
			}
			&[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav {
					.metismenu{
						&>li{
							&.mm-active{
								&>a{
									i{
										color:$white;
									}
								}
							}
						}				
					} 
				}
			}
			&[data-sidebar-style="mini"],
			&[data-sidebar-style="modern"],
			&[data-sidebar-style="compact"],
			&[data-sidebar-style="icon-hover"][data-layout="horizontal"],
			&[data-sidebar-style="full"][data-layout="horizontal"]
			{
				.deznav{
					.metismenu li ul{
						background-color: lighten($color: $color, $amount: 10%);
					}
				} 
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
					background-color: lighten($color: $color, $amount: 10%)!important;
				}
			}
			&[data-sidebar-style="compact"][data-layout="vertical"]{
				.deznav {
					.metismenu {
						&>li {
							a{
								&:hover{
									color:$white;
								}
							}
							&>a{
								&>i{
									background: lighten($color: $color, $amount: 3%)!important;
									color:rgba($white,0.7);
								}
							}
							&.mm-active,&:hover{
								&>a {
									box-shadow:none;
									background:transparent!important;
									color:$white!important;
									i{
										background: lighten($color: $color, $amount: 10%)!important;
										color:$white!important;
									}
									
								}
							}
						}
					}
				}
				&[data-sibebarbg="color_8"]{
					.plus-box{
						background:rgba($black,0.5);
					}
					.deznav .metismenu ul a:before {
						background:rgba($black,0.5);
					}
					.deznav {
						.metismenu{
							a{
								color:rgba($black,0.6)!important;	
								&:hover,
								&:focus,
								&.mm-active {
									color:$black!important;
									/* background:rgba($black,0.15)!important; */
								}
							}
							
							.has-arrow:after{
									border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
							}
							&>li{
								&>a{
									color:var(--primary);
									i{
										color:rgba($black,0.6)!important;
										background:var(--rgba-primary-1)!important;
									}
								}
								&.mm-active{
									&>a{
										background:transparent;
										box-shadow:none;
										color:var(--primary)!important;
										i{
											color:var(--primary)!important;
											background:var(--rgba-primary-1)!important;
										}
									}
								}
							}
						}
						.copyright p{
							color:rgba($black,0.6);							
						}
						.book-box{
							background: rgba($black,0.4);
						}
						.header-info{
							span{
								color:rgba($black,0.6)!important;
							}
							small{
								color:rgba($black,0.6)!important;
							}
						}
					}
				}
				
			}
			
			&[data-sidebar-style="icon-hover"]{
				.deznav {
					.metismenu {
						&>li {
							a{
								&:hover{
									color:$white;
								}
							}
							&>a{
								&>i{
									/* background: lighten($color: $color, $amount: 3%)!important; */
									color:rgba($white,0.7);
								}
							}
							&.mm-active,&:hover{
								&>a {
									box-shadow:none;
									background: lighten($color: $color, $amount: 10%)!important;
									color:$white;
									
								}
							}
						}
					}
				}
			}
			.deznav {
				.metismenu{
					ul{
						a{
							&:hover,
							&:focus, 
							&.mm-active {
								&:before{
									border-color:#fff;
								}
							}
							&:before{
								background:$white;	
							}
						}
					}
					a{
						color:rgba($white,0.8);
					}
					li{
						ul{
							a{
								&:hover,
								&:focus,
								&.mm-active {
									color:$white!important;
								}
							}
						}
					}
					&>li{
						&>a{
							color:rgba($white,0.85)!important;
							i{
								color:rgba($white,0.85)!important;
							}
						}
						&.mm-active,
						&:hover{
							&>a{
								background: lighten($color: $color, $amount: 10%)!important;
								box-shadow:none;
								color:$white!important;
								i{
									color:$white!important;
								}
							}
						}
					}
					.has-arrow:after{
						border-color: rgba($white,0.85) transparent transparent rgba($white,0.85);
					}					
				} 
				.header-profile > a.nav-link{
					border-color:rgba($white,0.3);
					.header-info{
						span{
							color:$white;
						}
						small{
							color:rgba($white,0.8);
						}
					} 
				}
			}
			.copyright{
				color:$white;
			}
			//special work for color
			&[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				.deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;	
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
								/* background:rgba($black,0.15)!important; */
							}
						}
						
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									box-shadow:none;
									color:$white!important;
									i{
										color:$white!important;
									}
								}
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info{
						span{
							color:rgba($black,0.6)!important;
						}
						small{
							color:rgba($black,0.6)!important;
						}
					}
				}
			}
			
		}
	}
}

//background for headerbg
@each $name, $color in $theme_backgrounds {
	[data-headerbg="#{$name}"][data-theme-version="dark"],
    [data-headerbg="#{$name}"] {
        @if $name != "color_1"  {
			--headerbg: #{$color};
			.search-area .input-group-text,
			.search-area .form-control,.search-area .form-control::placeholder{
				/* color:$white; */
			}
			&[data-sidebar-style="full"][data-layout="vertical"]{
				.menu-toggle .nav-header .nav-control .hamburger .line{
					background-color:$white;	
				}
			}
			.header-right{
				.search-area .form-control,
				.search-area .input-group-text{
					background-color: lighten($color: $color, $amount: 10%);
					i{
						color:$white;
					}
				}
				.notification_dropdown{
					.ai-icon{
						background-color: lighten($color: $color, $amount: 10%);
						svg{
							path{
								fill:$white;	
							}	
						}
					}	
				}
				.header-profile > a.nav-link{
					background-color: lighten($color: $color, $amount: 10%);	
					img{
						border-color:$white;	
					}
					.header-info{
						span{
							color:$white;	
						}
						
					}
				}
					
			}
			.header-left{
				.dashboard_bar{
					color:$white;	
				}	
			}
			 .hamburger .line{
				background: $white;;	
			}
			//special work for color
			&[data-headerbg="color_8"]{
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
				}
				.header-right{
					.search-area .form-control,
					.search-area .input-group-text{
						background:#F1F1F1;
						i{
							color:$black;
						}
					}
					.notification_dropdown{
						.ai-icon{
							background-color:#F1F1F1;
							svg{
								path{
									fill:$black;	
								}	
							}
						}	
					}
					.header-profile .nav-link .header-info {
						span,
						small{
							color: $black;	
						}
					}
						
				}
				.header-left{
					.dashboard_bar{
						color:$black;	
					}	
				}	
			}
			
			
			
		}
	}
}

@each $name, $color in $theme_textcolor {
	[data-sibebartext="#{$name}"][data-theme-version="dark"],
    [data-sibebartext="#{$name}"] {
        @if $name != "color_1"  {
			.deznav .metismenu a{
				color:$color;
			}
		}
	}
}

